<template>
  <div>
    <b-card>
      <label for="">
        nowa cena
      </label>
      <b-form-input style="width: 100%" />
    </b-card>
    <b-card>
      <button
        class="btn btn-danger"
        style="width:100%;"
        @click="deletePromotion"
      >
        usuń promocję
      </button>
      <button
        class="btn btn-success"
        style="width:100%; margin-top: 10px;"
        @click="updatePromotion"
      >
        aktualizuj promocję
      </button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import { BCard, BForm, BFormInput } from 'bootstrap-vue'
import swal from 'sweetalert2'

export default {
  title: 'Promotions',
  components: {
    BCard,
    BForm,
    BFormInput,
  },
  data() {
    return {
      promotion: [

      ],
    }
  },
  mounted() {
    document.title = 'Edytuj promocję - Pickmode'

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Promotion/${this.$route.params.id}`, config)
      .then(response => {
        this.promotion = response.data
      })
  },
  methods: {
    deletePromotion() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.delete(`${process.env.VUE_APP_API_URL}Promotion/${this.$route.params.id}`, config)
        .then(response => {
          swal.fire({
            title: 'Sukces',
            text: 'Zmiany zostały zapisane',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
          this.$router.push({ name: 'Promotions' })
        })
    },
  },
}
</script>

<style>

</style>
